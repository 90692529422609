@import "./_var-media.scss";

body {
    background: var(--background-default);
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 600px;
    }
}

nav {
    height: 72px;
    padding: 8px;

    svg {
        width: 140px;
        margin-left: 24px;
        margin-top: 20px;
    }
}

p {
    margin-bottom: 0;
}

a {
  color: var(--link-primary);

  &:hover {
    color: var(--link-primary-hover);
  }
}

label.block {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    margin-left: 10px;
}

label span {
  max-width: 100%;
  line-height: 1.5;
}

input[type=checkbox] {
    float: left;
    margin-top: 4px;
}

.checkbox__text::before {
  top: 0 !important;
} 

.checkbox__text::after {
  top: 7px !important;
} 

input.zip {
  margin-right: 0 !important;
}

select {
    border: 1px solid var(--input-border-default);
    border-radius: 4px;
    padding: 7px;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--text-default);
    background-image: url('../images/chevron-south.svg');
    background-repeat: no-repeat;
    background-position: right 8px top 50%;;
    cursor: default !important;

   @media #{$tablet} {
     width: 100%;
   }

   @media #{$mobile} {
     width: 100%;
   }

   &:hover {
    background-image: url('../images/chevron-south-hover.svg');
   }
}

input[type=text], textarea {
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
  color: var(--text-light);
}
::-moz-placeholder {
  color: var(--text-light);
}
:-ms-input-placeholder {
  color: var(--text-light);
}
:-moz-placeholder {
  color: var(--text-light);
}

// Form Detail
form {
  padding: 20px 0 0;
  border-top: 1px solid var(--slate-3);
}

.form-body {
  position: relative;
}

.form-text {
  color: var(--text-light);
  position: absolute;
  right: 0;
  top: 0;

  @media #{$mobile} {
    display: block;
    position: unset;
    margin-bottom: 30px;
  }
}

.form-error {
  color: var(--red-primary);
  width: 100%;
  margin: 0 0 .5em 0;
}

.custom-error {
  width: 50%;
}

.subtitle {
  border-top: 1px solid var(--slate-3);
}

.centered {
  text-align: center;

  .button {
    display: inline-flex;
  }
}

// Flex Column Definitions
.flex {
  display: flex;
  width: 100%;

  .col-1 {
    width: 100%;
    margin: .5rem 0 0 0;
  }

  .col-2 {
    width: 49.25%;
    margin: .5rem .5rem 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .col-3 {
    width: 32.55%;
    margin: 0 .4rem .5rem 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @media #{$mobile} {
    display: block;

    .col-2, .col-3 {
      width: 100%;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn-primary {
    background-color: var(--button-background-primary);
    border-color: var(--button-border-primary);
    padding: 12px 30px;
    cursor: pointer;

    @media #{$mobile} {
      word-wrap: normal;
      white-space: normal;
      width: 100%;
    }
}

.button--primary.disabled,
.button--primary:disabled,
.button--secondary.disabled,
.button--secondary:disabled {
    background-color: var(--background-disabled);
    border-color: var(--button-border-disabled);
    color: var(--slate-5);
    pointer-events: none;
}

.btn-secondary {
    padding: 12px 30px;

    @media #{$mobile} {
      word-wrap: normal;
      white-space: normal;
      width: 100%;
    }
}

.page-tos {
    p {
        margin-bottom: 20px;
    }

    .btn-secondary {
      margin-right: 8px;
    }

    @media #{$mobile} {
      .btn-secondary, .btn-primary {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
}

.cta-main {
    background: var(--background-light);
    box-shadow: 0 4px 16px 0 RGBA(4, 4, 4, 0.1);
    border-radius: var(--border-radius);
}

.cta-content {
    padding: 30px 20px;
    color: var(--text-default);
    margin-bottom: 3rem;
}

.contact-info {
    margin-top: 1rem;
}

#company-agency,
#company-marketer,
#company-marketer-other,
#company-other,
#region-error,
#addressState,
.address-province,
.address-region
.hide {
    display: none;
}

.container-btns {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    a {
      display: flex;
    }
}

.tos-box {
    border-radius: 5px;
    border: 1px solid var(--border-default);
    max-height: 400px;
    height: 400px;
    padding: 30px 30px 0px 30px;
    overflow: auto;
    background-color: white;
    font-size: 13px;
}

.tos-scroll-message {
    color: var(--red-secondary);
    font-size: 13px;
    margin-top: 8px;
    height: 30px;
}

.tos-checkboxes {
    margin-top: 17px;
    margin-bottom: -8px;
}
