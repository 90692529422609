:root {
  --light: #FFFFFF;
  --slate-0: #f8f9fa;
  --slate-1: #f1f3f5;
  --slate-2: #e9ecef;
  --slate-3: #dee2e6;
  --slate-4: #ced4da;
  --slate-5: #adb5bd;
  --slate-6: #868e96;
  --slate-7: #495057;
  --slate-8: #212529;
  --blue-primary: #4263EB;
  --blue-secondary: #364FC7;
  --blue-tertiary: #BAC8FF;
  --blue-quaternary: #EDF2FF;
  --green-primary: #0ca678;
  --green-secondary: #087f5b;
  --red-primary: #E03131;
  --red-secondary: #C92A2A;
  --red-tertiary: #FFC9C9;
  --red-quaternary: #FFF5F5;
  --yellow-primary: #fcc419;
  --yellow-secondary: #f59f01;
  --chart-1a: #0B7285;
  --chart-1b: #0CA678;
  --chart-1a: #51CF66;
  --chart-1d: #94D82D;
  --chart-1e: #FFD43B;
  --chart-2a: #5F3DC4;
  --chart-2b: #5C7CFA;
  --chart-2a: #74C0FC;
  --chart-2d: #99E9F2;
  --chart-2e: #38D9A9;
  --chart-3a: #C2255C;
  --chart-3b: #F03E3E;
  --chart-3a: #FD7E14;
  --chart-3d: #F59F00;
  --chart-3e: #FCC419;
  --chart-4a: #F03E3E;
  --chart-4b: #C2255C;
  --chart-4a: #862E9C;
  --chart-4d: #7048E8;
  --chart-4e: #748FFC;
}

:root {
  --background-default: var(--slate-0);
  --background-light: var(--light);
  --background-disabled: var(--slate-1);
  --border-radius: 5px;
  --border-default: var(--slate-3);
  --transition: all 300ms;
  --divider: var(--slate-2);
  --box-shadow: 0 4px 16px 0 RGBA(var(--slate-7), 0.1);
  --overlay: RGBA(var(--slate-7), 0.6);
  --focus-outline: var(--blue-tertiary) solid 2px;
  --success: var(--green-primary);
  --warning: var(--yellow-primary);
  --danger: var(--red-primary);
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --text-default: var(--slate-7);
  --text-medium: var(--slate-6);
  --text-light: var(--slate-5);
  font-size: 16px;
  --input-border-default: var(--slate-4);
  --input-background-default: var(--background-light);
  --input-focus-border-default: var(--blue-primary);
  --input-color-error: var(--red-secondary);
  --input-background-error: var(--red-quaternary);
  --input-border-error: var(--danger);
  --input-hover-border-error: var(--red-secondary);
  --input-focus-error: var(--red-tertiary);
  --table-row-background-active: var(--blue-quaternary);
  --table-row-border-active: var(--blue-tertiary);
}

:root {
  --icon-default:             var(--slate-5);
  --icon-default-hover:       var(--slate-7);
  --icon-light:               var(--slate-3);
  --icon-light-hover:         var(--slate-5);
  --icon-white:               var(--light);
  --icon-primary:             var(--blue-primary);
  --icon-primary-hover:       var(--blue-secondary);
  --icon-success:             var(--green-primary);
  --icon-success-hover:       var(--green-secondary);
  --icon-warning:             var(--yellow-primary);
  --icon-warning-hover:       var(--yellow-secondary);
  --icon-danger:              var(--red-primary);
  --icon-danger-hover:        var(--red-secondary);
}

.icon {
  height: 24px;
  width: 24px;
  fill: var(--icon-default);
}

.icon--sm {
  height: 16px;
  width: 16px;
}

.icon--md {
  height: 24px;
  width: 24px;
}

.icon--lg {
  height: 32px;
  width: 32px;
}

.icon--xl {
  height: 48px;
  width: 48px;
}

.icon--white {
  fill: var(--icon-white);
}

.icon--default {
  fill: var(--icon-default);
}

.icon--default:hover {
  fill: var(--icon-default-hover);
}

.icon--light {
  fill: var(--icon-light);
}

.icon--light:hover {
  fill: var(--icon-light-hover);
}

.icon--primary {
  fill: var(--icon-primary);
}

.icon--primary:hover {
  fill: var(--icon-primary-hover);
}

.icon--success {
  fill: var(--icon-success);
}

.icon--success:hover {
  fill: var(--icon-success-hover);
}

.icon--warning {
  fill: var(--icon-warning);
}

.icon--warning:hover {
  fill: var(--icon-warning-hover);
}

.icon--danger {
  fill: var(--icon-danger);
}

.icon--danger:hover {
  fill: var(--icon-danger-hover);
}

/* Helper Class to hide text visually but keep it accessible for screen reader or other assistive software. */
.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

body {
  font-family: var(--font-family);
  color: var(--text-default);
  font-size: 14px;
  line-height: 1;
}

:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--blue-tertiary);
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0 0 1rem;
  line-height: 1.3;
  font-weight: 400;
}

h1 {
  font-weight: 600;
  font-size: 3.75rem;
}

h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

h3 {
  font-weight: 600;
  font-size: 2rem;
}

h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

h5 {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

h6 {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

input, select, textarea {
  font-size: inherit;
  font-family: inherit;
}

p {
  margin: 1rem 0;
}

a {
  display: inline-flex;
  font-weight: bold;
  align-items: center;
  transition: var(--transition);
  cursor: pointer;
  text-decoration: none;
}

ul {
  padding-left: 1.2em;
}

strong {
  font-weight: bolder;
}

b,
.bold {
  font-weight: 600;
}

.small {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-align-center {
  text-align: center;
}

.text-medium,
.type-secondary {
  color: var(--text-light);
}

.subtitle {
  font-weight: 300;
}

:root {
  --button-background-primary: var(--blue-primary);
  --button-background-secondary: var(--slate-2);
  --button-background-danger: var(--red-primary);
  --button-border-primary: var(--blue-secondary);
  --button-border-secondary: var(--slate-4);
  --button-border-danger: var(--red-secondary);
  --button-border-disabled: var(--slate-3);
  --button-secondary-icon-fill: var(--slate-6);
}

.button {
  position: relative;
  display: inline-block;
  min-height: 40px;
  padding: 0 1rem;
  color: var(--light);
  font-size: inherit;
  font-weight: 700;
  border-radius: var(--border-radius);
  border: 1px solid;
  line-height: 1.7;
  transition: var(--transition);
  vertical-align: middle;
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  color: var(--slate-5);
  background-color: var(--background-disabled);
  border-color: var(--button-border-disabled);
  cursor: not-allowed;
}

.button--primary {
  background-color: var(--button-background-primary);
  border-color: var(--button-border-primary);
}

.button--primary:hover {
  background-color: var(--button-border-primary);
}

.button--secondary {
  background-color: var(--button-background-secondary);
  border-color: var(--button-border-disabled);
  color: var(--slate-7);
}

.button--secondary:hover {
  background-color: var(--button-border-disabled);
}

.button--danger {
  background-color: var(--button-background-danger);
  border-color: var(--button-border-danger);
}

.button--danger:hover {
  background-color: var(--button-border-danger);
}

.button--stand-alone {
  padding: 0;
  background-color: transparent;
  line-height: 0.8;
  border: none;
  min-height: auto;
}

.button__icon {
  margin-right: 0.25rem;
  margin-left: -0.625rem;
  vertical-align: top;
  fill: currentColor;
}

.button--secondary .button__icon {
  fill: var(--button-secondary-icon-fill);
}

.button + .button {
  margin-left: 1rem;
}

.input {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.57rem 0.2rem;
  border: 1px solid var(--input-border-default);
  border-radius: var(--border-radius);
  color: var(--text-default);
  background-color: var(--input-background-default);
  transition: var(--transition);
  box-sizing: border-box;
  line-height: 2;
  font-weight: 400;
  cursor: text;
}

.input::-webkit-input-placeholder {
  color: var(--text-light);
}

.input:-ms-input-placeholder {
  color: var(--text-light);
}

.input::-ms-input-placeholder {
  color: var(--text-light);
}

.input::placeholder {
  color: var(--text-light);
}

.input:hover {
  border-color: var(--slate-6);
}

.input:focus {
  border-color: var(--input-focus-border-default);
}

.input:disabled {
  background-color: var(--background-disabled);
  cursor: not-allowed;
}

.input:disabled:hover {
  border-color: var(--input-border-default);
}

.input--error {
  color: var(--input-color-error);
  background-color: var(--input-background-error);
  border-color: var(--input-border-error);
}

.input--error:hover {
  border-color: var(--input-hover-border-error);
}

.input--error:focus {
  box-shadow: 0 0 0 2px var(--input-focus-error);
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 0 0.5rem 0;
}

.label--inline-content {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}

.label__text {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--slate-7);
  font-weight: bold;
  text-transform: capitalize;
}

.label--required .label__text::after {
  content: ' *';
}

.label--inline-content .label__text {
  margin-bottom: 0;
  flex-shrink: 0;
}

.label--search .label__text {
  position: absolute;
  margin: 0.5rem 0 0 0.5rem;
}

.label--search .label__text::after {
  content: '';
}

.label--search .input {
  border-radius: 20px;
  padding-left: 2.125rem;
}

.label--inline-content.label--search .label__text {
  margin-top: 0.1rem;
}

.label__error-message {
  margin-top: 0.5rem;
  color: var(--red-primary);
  flex-shrink: 0;
}

.label--inline-content .label__error-message {
  margin-top: 0;
  margin-left: 0.5rem;
}

:root {
  --radio-border-color: var(--blue-secondary);
  --radio-disabled-background: var(--background-disabled);
  --radio-disabled-border-color: var(--slate-3);
  --radio-disabled-checked-background: var(--slate-5);
}

.radio {
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0 0.5rem 0 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid var(--radio-border-color);
  border-radius: 50%;
  transition: var(--transition);
}

.radio:checked {
  border-width: 6px;
}

.radio:disabled {
  background-color: var(--radio-disabled-background);
  border-color: var(--radio-disabled-border-color);
  cursor: not-allowed;
}

.radio:disabled.radio:checked {
  background-color: var(--radio-disabled-checked-background);
}

:root {
  --checkbox-border-color: var(--slate-4);
  --checkbox-hover-border-color: var(--slate-5);
  --checkbox-background-color: var(--background-light);
  --checkbox-checked-background-color: var(--blue-primary);
  --checkbox-checked-border-color: var(--blue-secondary);
  --checkbox-checked-hover-border-color: var(--blue-secondary);
  --checkbox-focus-color: var(--blue-tertiary);
  --checkbox-disabled-background-color: var(--slate-1);
  --checkbox-disabled-border-color: var(--slate-3);
  --checkbox-disabled-color: var(--slate-5);
}

.checkbox {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.checkbox__text {
  position: relative;
  display: inline-block;
  padding-left: 1.2rem;
}

.checkbox__text::before, .checkbox__text::after {
  position: absolute;
  display: inline-block;
  content: "";
  transition: var(--transition);
}

.checkbox__text::before {
  height: 20px;
  width: 20px;
  top: -4px;
  left: -8px;
  border: 1px solid var(--checkbox-border-color);
  border-radius: 3px;
  background-color: var(--checkbox-background-color);
}

.checkbox + .checkbox__text::after {
  content: none;
}

.checkbox:checked + .checkbox__text::after {
  content: "";
  color: var(--checkbox-background-color);
  height: 3px;
  width: 11px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 4px;
  left: -3px;
}

.checkbox:indeterminate + .checkbox__text::after {
  content: "";
  color: var(--checkbox-background-color);
  height: 0;
  width: 12px;
  border-bottom: 2px solid;
  top: 6px;
  left: -3px;
}

.checkbox:hover + .checkbox__text::before {
  border: 1px solid var(--checkbox-hover-border-color);
}

.checkbox:checked + .checkbox__text::before, .checkbox:indeterminate + .checkbox__text::before {
  background-color: var(--checkbox-checked-background-color);
  border: 1px solid var(--checkbox-checked-border-color);
}

.checkbox:checked + .checkbox:hover + .checkbox__text::before {
  border: 1px solid var(--checkbox-checked-hover-border-color);
}

.checkbox:focus + .checkbox__text::before {
  box-shadow: 0 0 0 2px var(--checkbox-focus-color);
}

.checkbox:disabled + .checkbox__text::before {
  background-color: var(--checkbox-disabled-background-color);
  border-color: var(--checkbox-disabled-border-color);
}

.checkbox:disabled + .checkbox__text::after {
  color: var(--checkbox-disabled-color);
}

:root {
  --textarea-border-default: var(--slate-4);
  --textarea-background-default: var(--background-light);
  --textarea-focus-border-default: var(--blue-primary);
  --textarea-color-error: var(--red-secondary);
  --textarea-background-error: var(--red-quaternary);
  --textarea-border-error: var(--danger);
  --textarea-hover-border-error: var(--red-secondary);
  --textarea-focus-error: var(--red-tertiary);
}

.textarea {
  display: inline-block;
  width: 100%;
  height: 4.5rem;
  padding: 0.2rem 0.4rem;
  border: 1px solid var(--textarea-border-default);
  border-radius: var(--border-radius);
  color: var(--text-default);
  background-color: var(--textarea-background-default);
  transition: var(--transition);
  box-sizing: border-box;
  line-height: 1.5;
  font-weight: 400;
  cursor: text;
}

.textarea::-webkit-input-placeholder {
  color: var(--text-light);
}

.textarea:-ms-input-placeholder {
  color: var(--text-light);
}

.textarea::-ms-input-placeholder {
  color: var(--text-light);
}

.textarea::placeholder {
  color: var(--text-light);
}

.textarea:hover {
  border-color: var(--slate-6);
}

.textarea:focus {
  border-color: var(--textarea-focus-border-default);
}

.textarea:disabled {
  background-color: var(--background-disabled);
  cursor: not-allowed;
}

.textarea:disabled:hover {
  border-color: var(--textarea-border-default);
}

.textarea--error {
  color: var(--textarea-color-error);
  background-color: var(--textarea-background-error);
  border-color: var(--textarea-border-error);
}

.textarea--error:hover {
  border-color: var(--textarea-hover-border-error);
}

.textarea--error:focus {
  box-shadow: 0 0 0 2px var(--textarea-focus-error);
}

:root {
  --link-primary: var(--blue-primary);
  --link-primary-hover: #1766B0;
  --link-primary-active: #145A9C;
  --link-dark: var(--slate-7);
  --link-dark-hover: #383D42;
  --link-dark-active: #222629;
  --link-dark-icon: var(--slate-5);
  --link-light: var(--light);
  --link-light-hover: rgba(255, 255, 255, 0.5);
  --link-rounded-text: var(--light);
  --link-dark-rounded-hover: rgba(255, 255, 255, 0.25);
  --link-rounded-background: var(--blue-primary);
  --link-rounded-hover: var(--link-primary-hover);
}

:root {
  --link-rounded-border-radius: var(--border-radius);
}

.link {
  color: var(--link-primary);
}

.link:hover {
  color: var(--link-primary-hover);
}

.link:active {
  color: var(--link-primary-active);
}

.link:focus {
  border-radius: var(--border-radius);
}

.link svg {
  fill: currentColor;
}

.link--dark {
  color: var(--link-dark);
}

.link--dark svg {
  fill: var(--link-dark-icon);
}

.link--dark:hover {
  color: var(--link-dark-hover);
}

.link--dark:active {
  color: var(--link-dark-active);
}

.link--light {
  color: var(--link-light);
}

.link--light:hover {
  color: var(--link-light-hover);
}

.link--rounded {
  color: var(--link-rounded-text);
  background: var(--link-rounded-background);
  padding: 1rem 1.5rem;
  border-radius: var(--link-rounded-border-radius);
  border: 2px solid transparent;
}

.link--rounded:hover {
  color: var(--light);
  background: var(--link-primary-hover);
}

.link--inverted {
  background: transparent;
  color: var(--link-primary);
  border: 2px solid var(--link-primary);
}

.link--inverted:hover {
  background: transparent;
  color: var(--link-primary-hover);
  border-color: var(--link-primary-hover);
}

.link--dark-inverted {
  background: transparent;
  color: var(--light);
  border: 2px solid var(--light);
}

.link--dark-inverted:hover {
  background: var(--link-dark-rounded-hover);
  color: var(--light);
  border-color: var(--light);
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--slate-7);
  opacity: 0.8;
  z-index: 999;
}

.arc-dialog-children {
  padding: 1.5rem;
}

.prompt {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 44rem;
  min-width: 20rem;
  border-radius: var(--border-radius);
  z-index: 1000;
}

.prompt__card {
  position: relative;
  min-height: 120px;
  width: 500px;
  padding: 0;
  background: var(--background-light);
  border-radius: var(--border-radius);
}

.prompt__card footer {
  position: relative;
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
}

.prompt__close {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
}

.page-notification {
  display: flex;
  width: 19rem;
  position: absolute;
  top: 0;
  right: -22rem;
  margin-top: 1.5rem;
  padding: 1rem;
  align-items: flex-start;
  background: var(--page-notification-background-color);
  color: var(--page-notification-color);
  font-size: 12px;
  font-weight: bold;
  border-radius: var(--border-radius);
  z-index: 20;
  box-shadow: var(--box-shadow);
  transition: opacity 1s ease;
  -webkit-animation: slidein 7s ease forwards;
          animation: slidein 7s ease forwards;
}

.page-notification--fadeout {
  opacity: 0;
}

.page-notification__text {
  padding: 0.2rem 0;
  word-break: break-word;
  max-width: 13.5rem;
  line-height: 1.5;
}

svg + .page-notification__text {
  margin-left: 0.25rem;
}

.page-notification__close {
  margin-left: auto;
  align-self: flex-start;
  background-color: var(---page-notification-background-color);
  z-index: 20;
}

.page-notification + .page-notification {
  top: 4rem;
}

.page-notification + .page-notification + .page-notification {
  top: 8rem;
}

.page-notification + .page-notification + .page-notification + .page-notification {
  top: 12rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 16rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 20rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 24rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 28rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 32rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 36rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 40rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 44rem;
}

.page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification + .page-notification {
  top: 48rem;
}

@-webkit-keyframes slidein {
  0% {
    right: -22rem;
  }
  15% {
    right: 1.5rem;
  }
  100% {
    right: 1.5rem;
  }
}

@keyframes slidein {
  0% {
    right: -22rem;
  }
  15% {
    right: 1.5rem;
  }
  100% {
    right: 1.5rem;
  }
}

:root {
  --page-notification-color: var(--light);
  --page-notification-background-color: var(--slate-8);
}

.modal {
  display: block;
  position: fixed;
  max-height: 73vh;
  min-width: 17rem;
  max-width: 41rem;
  top: 6.25rem;
  left: 0;
  right: 0;
  bottom: unset;
  margin: auto;
  padding: 1.5rem;
  background: var(--background-light);
  border-radius: 5px;
  z-index: 1000;
  overflow-y: auto;
}

.modal__title {
  display: flex;
  justify-content: space-between;
}

.modal__content {
  padding: 1rem 0 0;
}

.modal footer {
  position: relative;
}

.modal .select-menu {
  position: relative;
  top: 0;
}

.breadcrumb {
  list-style: none;
  display: inline-block;
}

.breadcrumb--current {
  font-weight: bold;
}

.breadcrumb--current .link:active {
  color: var(--link-primary);
}

.breadcrumb--current .link:hover {
  color: var(--link-primary);
  cursor: default;
}

.breadcrumb--current .link:focus {
  color: var(--link-primary);
  pointer-events: none;
}

:root {
  --pill-background-primary: var(--blue-primary);
  --pill-border-primary: var(--blue-secondary);
  --pill-color-primary: var(--light);
  --pill-background-secondary: var(--slate-1);
  --pill-border-secondary: var(--slate-3);
  --pill-color-secondary: var(--slate-7);
  --pill-background-badge: var(--slate-2);
  --pill-color-badge: var(--slate-6);
}

.pill {
  position: relative;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  height: 1.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--pill-border-primary);
  color: var(--pill-color-primary);
  background-color: var(--pill-background-primary);
}

.pill svg {
  fill: var(--light);
}

.pill:hover {
  cursor: default;
  background-color: var(--pill-border-primary);
}

.pill--disabled {
  opacity: 0.5;
  cursor: default;
}

.pill--disabled:hover {
  background-color: var(--pill-background-primary);
}

.pill--secondary {
  background-color: var(--pill-background-secondary);
  border-color: var(--pill-border-secondary);
  color: var(--pill-color-secondary);
}

.pill--secondary svg {
  fill: var(--pill-color-secondary);
}

.pill--secondary:hover {
  background-color: var(--pill-border-secondary);
}

.pill--secondary.pill--disabled:hover {
  background-color: var(--pill-background-secondary);
}

.pill--secondary .pill__cancel:disabled {
  background-color: var(--pill-background-secondary);
}

.pill--secondary .pill__cancel:disabled:hover {
  background-color: var(--pill-background-secondary);
}

.pill__text {
  height: 1.125rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.125rem;
  padding: 3px 0.5rem 0px 5px;
}

.pill__cancel {
  background: transparent;
  padding-left: 0px;
  padding-right: 0px;
}

.pill__cancel:disabled {
  background-color: var(--pill-background-primary);
}

.pill-badge {
  position: relative;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-size: 12px;
  font-weight: 500;
  height: 1rem;
  background-color: var(--pill-background-badge);
  color: var(--pill-color-badge);
  cursor: default;
  border-radius: 3px;
}

.pill-badge__text {
  padding-right: 5px;
  padding-left: 5px;
}

